<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <AppPageHeader title="Liquida nota comercial" subtitle="Visualize aqui o detalhamento da operação selecionada" />
    </b-card>
    <b-row>
      <b-col sm="12">
        <b-card>
          <liquidation-card :comercialNote="comercialNote" />
          <h4 class="mb-2">Informe os detalhes da liquidação da nota</h4>
          <b-row>
            <b-col sm="6" md="3" l="3" xl="3">
              <v-select id="internal-classification" v-model="liquidation.type" :clearable="false" :options="liquidationTypes" :reduce="type => type.value" class="per-page-selector d-inline-block w-100" />
            </b-col>
            <b-col sm="6" md="3" l="3" xl="3">
              <flat-pickr v-model="liquidation.date" class="form-control mb-1 bg-white" :config="config" placeholder="Data da liquidacao" />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6" md="3" l="3" xl="3">
              <b-form-group label="Valor atualizado" label-for="taxa-escrituracao">
                <b-form-input id="value" v-model="this.comercialNote.valor_devido_atraso" name="value" disabled />
              </b-form-group>
            </b-col>
            <b-col sm="6" md="3" l="3" xl="3" class="d-flex justify-content-start align-items-end">
              <b-button variant="primary" class="mb-1" @click="$bvModal.show('confimationModal')" :disabled="liquidation.date === ''">Liquidar Nota Comercial</b-button>
            </b-col>
          </b-row>
          <div v-if="liquidation.type === 'delay'">
            <h4>Total de encargos por atraso</h4>
            <h3>{{ comercialNote.encargos_por_atraso }}</h3>
            <h5>A multa daria de {{ comercialNote.multa_diaria }} ({{ comercialNote.multa_atraso }} sobre o valor à pagar + Mora de {{ comercialNote.mora_atraso }} a.m.)</h5>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="confimationModal" centered hide-footer header-bg-variant="white">
      <div class="d-flex flex-column justify-content-center">
        <h1 class="mb-1 text-primary text-left">Liquidar Nota Comercial</h1>
        <p class="h5 text-left mb-2 mt-2">
          Você deseja liquidar a Nota Comercial selecionada?
          Esta ação não poderá ser desfeita.
        </p>
        <div class="d-flex flex-row justify-content-around mt-1">
          <b-button variant="outline-primary" class="mb-1 mr-1" @click="$bvModal.hide('confimationModal')">Cancelar</b-button>
          <b-button variant="primary" class="mb-1" @click="sendLiquidation">Liquidar Nota Comercial</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AppPageHeader from "@core/components/app-page-header/AppPageHeader";
import { BCard, BCol, BRow, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import LiquidationCard from "@/views/admin/concessions/components/liquidation/LiquidationCard"
import {
  moneyFormat,
  comercialNoteStatusBadgeForAdmin,
  liquidationStatusBadge,
  percentageFormatter
} from '@/@core/comp-functions/data_visualization/datatable'
import vSelect from 'vue-select'
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { formatDate } from '@/@core/utils/filter'
import format from "date-fns/format";
import parse from "date-fns/parse";
import { brlToFloat } from '@/@core/comp-functions/money/money'

export default {
  name: "LiquidationPage",
  components: { AppPageHeader,
    LiquidationCard,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    flatPickr
  },
  filters: {
    comercialNoteStatusBadgeForAdmin
  },
  async mounted() {
    await this.getData()
  },
  data() {
    return {
      liquidationTypes: [
        { value: 'day', label: 'Pagamento em dia' },
        { value: 'delay', label: 'Pagamento com atraso' },
      ],
      config: {
        dateFormat: "d/m/Y",
        disable: [this.disablingWeekends],
        locale: Portuguese,
      },
      comercialNote: {},
      liquidation: {
        type: 'day',
        date: ''
      },
    }
  },
  watch: {
    'liquidation.date': {
      handler(newDate, oldDate) {
        if (this.liquidation.type === 'day') return
        if (newDate !== oldDate) {
          this.getPreviewEncargosWithNewDate(newDate);
        }
      },
      immediate: true,
    },
    'liquidation.type': {
      async handler(selectedType) {
        if (selectedType === 'day') {
          this.comercialNote.valor_devido_atraso = this.comercialNote.valor_devido
          this.liquidation.date = null
        } 
        if (selectedType === 'delay') {
          this.liquidation.date = null
          this.getPreviewEncargosWithNewDate()
        }
      },
      immediate: true,
    }
  },
  methods: {
    async getPreviewEncargosWithNewDate(newDate) {
      try {
        console.log(newDate)
        const response = await this.$store.dispatch('concession/getPreviewEncargos', { id: this.$route.params.id, data_pagamento: newDate})

        const valor_com_encargos = response.data.valor_devido_com_atraso
        const valor_devido = response.data.valor_devido

        if ((valor_com_encargos - valor_devido) <= "0.0") {
          this.comercialNote.valor_devido_atraso = moneyFormat(valor_devido)
          this.comercialNote.encargos_por_atraso = "0"

          return
        }

        this.comercialNote.encargos_por_atraso = moneyFormat(valor_com_encargos - valor_devido)
        this.comercialNote.valor_devido_atraso = moneyFormat(valor_com_encargos)
      } catch (error) {
        console.error('Erro ao chamar a API:', error);
      }
      console.log(this.comercialNote.valor_devido_atraso)
    },
    async getData() {
      const { data } = await this.$store.dispatch('concession/adminFetch', this.$route.params.id)
      this.comercialNote = data
      this.comercialNote.data_emissao = formatDate(this.comercialNote.data_emissao, 'DD/MM/YYYY')
      this.comercialNote.data_vencimento = formatDate(this.comercialNote.data_vencimento, 'DD/MM/YYYY')
      this.comercialNote.taxa_juros = percentageFormatter(this.comercialNote.taxa_juros)
      this.comercialNote.status = comercialNoteStatusBadgeForAdmin(this.comercialNote.status)
      this.comercialNote.quitacao = liquidationStatusBadge(this.comercialNote.quitacao)
      this.comercialNote.encargos_por_atraso = moneyFormat(this.comercialNote.encargos_por_atraso - this.comercialNote.valor_devido)
      this.comercialNote.valor_devido = moneyFormat(this.comercialNote.valor_devido)
      this.comercialNote.valor_emissao = moneyFormat(this.comercialNote.valor_emissao)
      this.comercialNote.multa_diaria = moneyFormat(this.comercialNote.multa_diaria)
      this.comercialNote.multa_atraso = percentageFormatter(this.comercialNote.multa_atraso)
      this.comercialNote.mora_atraso = percentageFormatter(this.comercialNote.mora_atraso)
      this.comercialNote.taxa_escrituracao = percentageFormatter(this.comercialNote.taxa_escrituracao)
      this.comercialNote.valor_devido_atraso = this.comercialNote.valor_devido
    },
    disablingWeekends(date) {
      return [0, 6].includes(date.getDay());
    },
    async sendLiquidation() {
      this.$swal.showLoading()
      if (this.liquidation.type === 'day') {
        const { data } = await this.$store.dispatch('concession/liquidate', {
          nota_comercial_id: this.$route.params.id,
          data_liquidacao: format(parse(this.liquidation.date, "dd/MM/yyyy", new Date()), "yyyy/MM/dd"),
          valor_liquidado: brlToFloat(this.comercialNote.valor_devido)
        })
      }
      if (this.liquidation.type === 'delay') {
        const { data } = await this.$store.dispatch('concession/liquidateLate', {
          id: this.$route.params.id,
          data_liquidacao: format(parse(this.liquidation.date, "dd/MM/yyyy", new Date()), "yyyy/MM/dd"),
        })
      }

      this.$bvModal.hide('confimationModal')
      this.$swal.fire({
        title: 'Sucesso!',
        text: 'Nota Comercial liquidada com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
      this.$router.push({ name: 'admin-concessions.index'})
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
